import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

export const HamburgerMenuWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: fixed;
  z-index: 10;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.hamburgerMenu.background};
  padding: 0 36px;
  height: 100%;
  min-height: 100%;
  width: 100%;
  overflow: scroll;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding: 36px 0;
`

export const Nav = styled(motion.nav)``

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
`

export const NavItem = styled.li`
  &:last-of-type {
    a {
      border-bottom: none;
    }
  }
`

export const NavLink = styled.a`
  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 18px 0;
  color: ${({ theme }) => theme.colors.hamburgerMenu.navList.item.text};
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.hamburgerMenu.navList.item.border};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.hamburgerMenu.navList.item.text};
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 36px 0 48px;
`

export const CloseIconWrapper = styled.div`
  cursor: pointer;
`
