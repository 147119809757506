import styled from 'styled-components'

// Types
import { MainNavbarWrapperProps } from './MainNavbar.types'

export const MainWrapper = styled.div`
  isolation: isolate;
  position: sticky;
  z-index: 40;
  top: 20px;
  padding: 0 12px 0;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding: 0 54px 0;
  }
`

export const MainNavbarWrapper = styled.div<MainNavbarWrapperProps>`
  display: flex;
  position: static;
  justify-content: space-between;
  align-items: center;
  border: 1px solid
    ${({ theme, darkBackground }) =>
      darkBackground
        ? theme.colors.mainNavbar.borderDarkBg
        : theme.colors.mainNavbar.borderLightBg};
  border-radius: 66px;
  padding: 10px 28px;
  backdrop-filter: ${({ darkBackground }) =>
    !darkBackground && 'blur(36.302799224853516px)'};
  background: ${({ theme, darkBackground }) =>
    darkBackground
      ? theme.colors.mainNavbar.backgroundDarkBg
      : theme.colors.mainNavbar.backgroundLightBg};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding: 0 28px;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    padding-left: 84px;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    gap: 30px;
  }
`

export const HamburgerIconWrapper = styled.div`
  cursor: pointer;
`

export const AveLogoWrapper = styled.a`
  cursor: pointer;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    svg {
      height: 70px;
    }
  }
`
