const PhoneIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.102'
      height='16.124'
      viewBox='0 0 17.102 16.124'
    >
      <path
        fill='transparent'
        fillRule='evenodd'
        stroke='#d90017'
        strokeWidth='1'
        d='M12.684 15.618a5.184 5.184 0 01-1.65-.262 15.992 15.992 0 01-8.585-6.325A9.16 9.16 0 01.591 5.314a4.437 4.437 0 011.436-4.28 2.554 2.554 0 012.11-.3.807.807 0 01.372.423q.69 1.63 1.368 3.272a.761.761 0 01-.048.624 4.953 4.953 0 01-.981 1.309.657.657 0 00-.087.916 10.909 10.909 0 005.4 4.633.637.637 0 00.842-.2c.387-.484.8-.955 1.168-1.457a.653.653 0 01.893-.24c.773.358 1.542.724 2.311 1.086l.515.249c.782.388.771.4.706 1.256-.13 1.684-1.393 2.513-2.888 2.927a3.346 3.346 0 01-1.024.086z'
        data-name='Path 136'
      ></path>
    </svg>
  )
}
export default PhoneIcon
