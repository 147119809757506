import { useRouter } from './router'
import { prop } from 'ramda'

interface DictionaryLine {
  cs: string
  en: string
  sk?: string
}

interface DynamicDictionaryLine {
  cs: (params: any) => string
  en: (params: any) => string
  sk?: (params: any) => string
}

const useDictionaryLine = (line: DictionaryLine): string => {
  const { locale } = useRouter()
  return prop(locale || 'cs', line) || line.cs || '* Chybějící překlad'
}

export const useDynamicDictionaryLine = (line: DynamicDictionaryLine) => {
  const { locale } = useRouter()
  return prop(locale || 'cs', line) || line.cs || (() => '* Chybějící překlad')
}

export default useDictionaryLine
