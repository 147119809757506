const RightArrowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.627'
      height='9.899'
      viewBox='0 0 22.627 9.899'
    >
      <g
        fill='none'
        stroke='#d90017'
        strokeMiterlimit='10'
        strokeWidth='1'
        data-name='Group 513'
        transform='translate(-482.79 -176.3)'
      >
        <path
          d='M21.213 0L0 0'
          data-name='Line 54'
          transform='translate(482.79 181.25)'
        ></path>
        <path d='M500.114 176.654l4.6 4.6-4.6 4.6' data-name='Path 29'></path>
      </g>
    </svg>
  )
}
export default RightArrowIcon
