// Styled Components
import { LinkedInIconWrapper } from './LinkedInIcon.styles'

const LinkedInIcon = () => {
  return (
    <LinkedInIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='28.86'
      height='27.585'
      data-name='Group 64'
      viewBox='0 0 28.86 27.585'
    >
      <path
        fill='#d90017'
        fillRule='evenodd'
        d='M4886.186 3139.546v-18.612H4880v18.612zm-3.092-21.153a3.447 3.447 0 10-.041 0z'
        data-name='Path 21'
        transform='translate(-4879.634 -3111.961)'
      ></path>
      <path
        fill='#d90017'
        fillRule='evenodd'
        d='M4921.583 3166.907h6.187v-10.394a4.238 4.238 0 01.2-1.509 3.387 3.387 0 013.175-2.264c2.238 0 3.133 1.708 3.133 4.209v9.958h6.187v-10.672c0-5.717-3.051-8.377-7.122-8.377a6.166 6.166 0 00-5.617 3.137h.041v-2.7h-6.187c.081 1.746 0 18.612 0 18.612z'
        data-name='Path 22'
        transform='translate(-4911.608 -3139.322)'
      ></path>
    </LinkedInIconWrapper>
  )
}
export default LinkedInIcon
