import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

export const LanguageSwitcherWrapper = styled.div`
  isolation: isolate;
  position: relative;
  width: fit-content;
  z-index: 20;
`

export const Button = styled.button`
  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.hamburgerMenu.languageSwitcher.text};
  border: none;
  outline: none;
  background-color: ${({ theme }) =>
    theme.colors.hamburgerMenu.languageSwitcher.background};

  svg {
    height: 10px;
    position: relative;
    top: -2px;

    path {
      stroke: ${({ theme }) =>
        theme.colors.hamburgerMenu.languageSwitcher.text};
    }
  }
`

export const List = styled(motion.ul)`
  position: absolute;
  top: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  cursor: pointer;
  padding: 6px 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
`

export const ListItem = styled.li`
  text-align: center;
  padding: 6px 6px;
`
