const CloseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.061'
      height='25.061'
      viewBox='0 0 25.061 25.061'
    >
      <g
        fill='none'
        stroke='#d90017'
        strokeWidth='1.5'
        data-name='Group 512'
        transform='translate(-301.97 -16.97) translate(302.5 17.5)'
      >
        <path d='M24 0L0 24' data-name='Line 275'></path>
        <path d='M0 0L24 24' data-name='Line 276'></path>
      </g>
    </svg>
  )
}
export default CloseIcon
