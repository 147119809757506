import styled from 'styled-components'

export const SocialIconsBarWrapper = styled.ul`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ListItem = styled.li``

export const ListLink = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) =>
    theme.colors.socialIconsBar.icon.background};
  transition: background-color 0.3s;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    width: 60px;
    height: 60px;
  }

  svg {
    path {
      transition: fill 0.3s;
    }
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.socialIconsBar.icon.backgroundHover};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.socialIconsBar.icon.colorHover};
      }
    }
  }

  &:active {
    background: ${({ theme }) =>
      theme.colors.socialIconsBar.icon.backgroundActive};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.socialIconsBar.icon.colorActive};
      }
    }
  }
`
