import styled from 'styled-components'

export const LinkedInIconWrapper = styled.svg`
  height: 18px;
  position: relative;
  bottom: 2px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    bottom: 3px;
    height: 28px;
  }
`
