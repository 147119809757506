// Styled Components
import { YoutubeIconWrapper } from './YoutubeIcon.styles'

const YoutubeIcon = () => {
  return (
    <YoutubeIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='33.498'
      height='23.152'
      data-name='Group 63'
      viewBox='0 0 33.498 23.152'
    >
      <g data-name='Group 21'>
        <path
          fill='#d90017'
          d='M3177.818 2442.45h-22.931a5.284 5.284 0 00-5.284 5.284v12.571a5.3 5.3 0 005.284 5.3h22.931a5.3 5.3 0 005.283-5.3v-12.571a5.283 5.283 0 00-5.283-5.284zm-14.578 20.387V2445.2l12.129 8.824z'
          data-name='Path 15'
          transform='translate(-3149.604 -2442.45)'
        ></path>
      </g>
    </YoutubeIconWrapper>
  )
}
export default YoutubeIcon
