export const isText = (data: any) => {
  return typeof data === 'string'
}

export const formatLocale = (locale: string | undefined) => {
  if (locale === 'cs') {
    return 'CZ'
  } else {
    return locale?.toUpperCase()
  }
}

export const formatDate = (date: string | Date) => {
  const formatedDate = new Date(date)
  return formatedDate.toLocaleDateString('cs-CZ').replace(/\.\s/g, '/');
}

export const skLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk'
