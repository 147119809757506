// React components
import Header from '../Header'
import Footer from '../Footer'
import MainNavbar from '../MainNavbar'

// Styled Components
import { LayoutWrapper, Main } from './Layout.styles'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Types
import { LayoutProps } from './Layout.types'

// COLORS
import { COLORS } from '../../utils/constants'

const Layout = ({
  children,
  serviceList,
  darkBackground = false,
  darkBackgroundMobile,
  background = COLORS.white,
  gradientBg = false,
  clipBackground = false,
  career = false,
}: LayoutProps) => {
  return (
    <LayoutWrapper
      background={background}
      gradientBg={gradientBg}
      clipBg={clipBackground}
    >
      {/* {!process.env.NEXT_PUBLIC_IS_CAREER &&
        process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk' && (
          <div
            style={{
              paddingInline: '16px',
              paddingBlock: '8px',
              fontSize: '1.1rem',
              textAlign: 'center',
              color: 'red',
            }}
          >
            Web je v rekonstrukci
          </div>
        )} */}

      <Header
        serviceList={serviceList}
        clipBackground={clipBackground}
        darkBackground={darkBackground}
        career={career}
      />
      <MediaContextProvider disableDynamicMediaQueries>
        <Media lessThan='md'>
          <MainNavbar
            darkBackground={
              darkBackgroundMobile ? darkBackgroundMobile : darkBackground
            }
            serviceList={serviceList}
            career={career}
          />
        </Media>
      </MediaContextProvider>
      <MediaContextProvider disableDynamicMediaQueries>
        <Media greaterThanOrEqual='md'>
          <MainNavbar
            darkBackground={darkBackground}
            serviceList={serviceList}
            career={career}
          />
        </Media>
      </MediaContextProvider>

      <Main>{children}</Main>
      <Footer serviceList={serviceList} />
    </LayoutWrapper>
  )
}
export default Layout
