import styled from 'styled-components'

export const RegionMapSkWrapper = styled.svg`
  text,
  path {
    pointer-events: none;
  }
`

export const Link = styled.a`
  > path {
    cursor: pointer;
    pointer-events: revert !important;
  }

  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.grey80};
    }
  }
`
