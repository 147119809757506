import styled from 'styled-components'

export const HeaderWrapper = styled.header`
  padding: 18px 12px 0;
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding: 18px 54px 0;
  }
`
