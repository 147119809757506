// React
import { useState, useRef, useEffect } from 'react'

// Hooks
import useClickOutside from '../../../hooks/useClickOutside'

// React components
import SearchIcon from '../../svgs/icons/SearchIcon'

// Styled Components
import { SearchWrapper, Label, Input, SearchIconWrapper } from './Search.styles'
import VisuallyHidden from '../../../styled-components/shared/VisuallyHidden'

// Types
import { SearchProps } from './Search.types'
import { useRouter } from '../../../hooks/router'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'

const Search = ({ darkBackground }: SearchProps) => {
  const router = useRouter()
  const ref = useRef(null)
  const inputRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState('')
  const searchLocale = useDictionaryLine(dictionary.mainNavigation.search)

  const onChange = (event: any) => setValue(event.target.value)

  const variants = {
    open: { width: 270, borderRadius: '20px' },
    close: {
      width: 41,
      borderRadius: '50%',
      transition: { duration: 0.15, type: 'tween' },
    },
  }

  useClickOutside(ref, () => {
    setIsOpen(false)
  })

  // Add focus when input is open & remove focus when input is closed
  useEffect(() => {
    if (inputRef.current) {
      if (!isOpen) {
        if (
          document.activeElement instanceof HTMLElement &&
          document.activeElement === inputRef.current
        ) {
          document.activeElement.blur()
        }
      } else {
        inputRef.current.focus()
      }
    }
  }, [isOpen])

  const onClick = () => {
    if (isOpen && value) {
      router.push({
        pathname: '/vyhledavani',
        query: {
          phrase: value,
        },
      })
    }
  }

  return (
    <SearchWrapper
      ref={ref}
      variants={variants}
      open={isOpen}
      initial='close'
      animate={isOpen ? 'open' : 'close'}
      onClick={() => {
        if (!isOpen) {
          setIsOpen(true)
        }
      }}
    >
      <SearchIconWrapper
        title={searchLocale}
        role='button'
        open={isOpen}
        darkBackground={darkBackground}
        onClick={onClick}
        aria-label='Vyhledávání'
      >
        <VisuallyHidden>{searchLocale}</VisuallyHidden>
        <SearchIcon />
      </SearchIconWrapper>
      <Label htmlFor='search-input'>{searchLocale}</Label>
      <Input
        id='search-input'
        value={isOpen ? value : ''}
        onChange={onChange}
        ref={inputRef}
        open={isOpen}
        darkBackground={darkBackground}
        type='text'
        autoFocus={true}
        placeholder={isOpen ? `${searchLocale}…` : ''}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            onClick()
          }
        }}
      />
    </SearchWrapper>
  )
}
export default Search
