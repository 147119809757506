// Styled Components
import {
  SocialIconsBarWrapper,
  ListItem,
  ListLink,
} from './SocialIconsBar.styles'

// Types
import { SocialIconsBarProps } from './SocialIconsBar.types'

const SocialIconsBar = ({ icons }: SocialIconsBarProps) => {
  return (
    <SocialIconsBarWrapper>
      {icons.map(({ href, icon, title, onClick }, i) => {
        return (
          <ListItem key={i}>
            {onClick ? (
              <ListLink
                onClick={onClick}
                title={title ? title : 'Sociální síť'}
                aria-label={title ? title : 'Sociální síť'}
              >
                {icon}
              </ListLink>
            ) : (
              <ListLink
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                title={title ? title : 'Sociální síť'}
                aria-label={title ? title : 'Sociální síť'}
              >
                {icon}
              </ListLink>
            )}
          </ListItem>
        )
      })}
    </SocialIconsBarWrapper>
  )
}
export default SocialIconsBar
