import { NextRouter, useRouter as useNextRouter } from "next/router"
import { useCallback } from "react"
import { getLangTranslate } from "../components/LanguageSwitcher/LanguageSwitcher"
import translateMap from "../translateMap"
import { compose, reduce, map, assoc, values, mapObjIndexed } from 'ramda'
const flipKeysAndValue = compose(
  reduce((a, b: object) => ({ ...a, ...b }), {}),
  map((vals: any) => assoc(vals[1], vals[0], {})),
  values,
  mapObjIndexed((value, key) => [key, value])
)

const reversedTranslateMap = flipKeysAndValue(translateMap)
export const useRouter = () => {
  const router = useNextRouter()
  const push = useCallback((q: string | object, a?: any, b?: any) => {
    const locale = router.locale
    const translate = getLangTranslate(locale === 'en' ? translateMap : reversedTranslateMap)
    if (typeof q === 'string') {
      router.push(translate(q).replace('/career', ''), a, b)
    } else {
      router.push({
        ...q,
        // @ts-ignore
        pathname: q.pathname && translate(q.pathname).replace('/career', ''),
      }, a, b)
    }
  }, [router])
  return {
    ...router,
    push,
  } as NextRouter
}