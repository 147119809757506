import create from 'zustand'
import { Store } from '../types/store'

const useStore = create<Store.State>(set => ({
  menuOpen: false,
  openMenu: () => {
    set({ menuOpen: true })
  },
  closeMenu: () => {
    set({ menuOpen: false })
  },
  toggleMenu: () => {
    set(state => ({ menuOpen: !state.menuOpen }))
  },
  subNav: '',
  setSubNav: (subNav: string) => {
    set({ subNav })
  },
  submenu: '',
  setSubmenu: (submenu: Store.SubmenuViewType) => {
    set({ submenu })
  },
}))

export default useStore
