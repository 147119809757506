import styled, { css } from 'styled-components'

// Types
import { ContentProps, BlockProps } from './Submenu.types'

export const SubmenuWrapper = styled.div`
  position: absolute;
  overflow: auto;
  z-index: 20;
  top: 100%;
  width: 100vw;
  left: 0;
  right: 0;
  max-height: calc(100vh - 175px);
  max-width: 1920px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.16));
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 480px;
  height: 320px;
`
export const ContentWrapper = styled.div`
  padding-top: 2px;
  height: 100%;
  overflow: auto;
`
export const Content = styled.div<ContentProps>`
  padding: 30px 28px;
  overflow: auto;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  border: 1px solid ${({ theme }) => theme.colors.submenu.border};
  background-color: ${({ theme }) => theme.colors.submenu.background};

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    grid-template-columns: repeat(4, 1fr);
  }

  ${({ locations }) =>
    locations &&
    css`
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 36px;

      @media (${({ theme }) => theme.mediaQueries.xxl}) {
        grid-template-columns: auto auto;
        gap: 140px;
      }
    `}
`
export const Block = styled.ul<BlockProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 100%;

  ${({ locations }) =>
    locations &&
    css`
      max-height: 400px;
      flex-wrap: wrap;
      padding: 60px 0;
      column-gap: 24px;
      min-width: 460px;

      @media (${({ theme }) => theme.mediaQueries.xxl}) {
        column-gap: 140px;
      }
    `}
`

export const TitleItem = styled.div`
  margin-bottom: 12px;
`

export const TitleLink = styled.a`
  text-decoration: none;
  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme }) => theme.colors.submenu.title};
`

export const BlockItem = styled.li``

export const BlockLink = styled.a`
  display: block;
  text-decoration: none;
  font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme }) => theme.colors.submenu.item};

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.submenu.itemHover};
    text-decoration: none;
  }

  &:active {
    color: ${({ theme }) => theme.colors.submenu.itemActive};
  }
`

export const ExternalLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
