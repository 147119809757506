import styled, { css } from 'styled-components'

// Types
import { NavItemProps } from './NavList.types'

export const NavListWrapper = styled.ul`
  display: flex;
  gap: 30px;
`

export const NavItem = styled.li<NavItemProps>`
  position: relative;
  font-size: ${({ theme }) => (17 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme, darkBackground }) =>
    darkBackground
      ? theme.colors.mainNavbar.navList.item.textDarkBg
      : theme.colors.mainNavbar.navList.item.textLightBg};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 81px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.mainNavbar.navList.item.border};

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 100%;
    height: 2px;
  }

  svg {
    transition: all 0.3s;
    position: relative;
    top: -1px;

    path {
      stroke: ${({ theme, darkBackground }) =>
        darkBackground
          ? theme.colors.mainNavbar.navList.item.textDarkBg
          : theme.colors.mainNavbar.navList.item.textLightBg};
    }
  }

  ${({ active }) =>
    active &&
    css`
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.mainNavbar.navList.item.borderHover};
      svg {
        top: 2px;
        transform: rotate(180deg);
      }
    `}

  &:hover {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.mainNavbar.navList.item.borderHover};
    svg {
      top: 2px;
      transform: rotate(180deg);
    }
  }

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};
  }
`
