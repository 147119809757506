import styled from 'styled-components'

// Types
import { NavLinkProps, IconsProps } from './TopNavbar.types'

export const TopNavbarWrapper = styled.div`
  position: relative;
  z-index: 45;
  padding-right: 28px;
  padding-left: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    padding-left: 99px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Nav = styled.nav``

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
`

export const NavItem = styled.li``

export const NavLink = styled.a<NavLinkProps>`
  display: block;
  padding: 24px 0;
  font-size: ${({ theme }) => (16 - 2) / theme.fontSizes.root + 'rem'};
  color: ${({ theme, darkBackground }) =>
    darkBackground
      ? theme.colors.topNavbar.navItemDarkBg
      : theme.colors.topNavbar.navItemLightBg};
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 2px solid
    ${({ theme }) => theme.colors.topNavbar.navItemBorder};

  &:hover {
    text-decoration: none;
    color: ${({ theme, darkBackground }) =>
      darkBackground
        ? theme.colors.topNavbar.navItemHoverDarkBg
        : theme.colors.topNavbar.navItemHoverLightBg};
    border-bottom: 2px solid
      ${({ theme, darkBackground }) =>
        darkBackground
          ? theme.colors.topNavbar.navItemBorderHoverDarkBg
          : theme.colors.topNavbar.navItemBorderHoverLightBg};
  }
`

export const Icons = styled.div<IconsProps>`
  display: flex;
  align-items: center;
  gap: 18px;

  a {
    // LinkedIn icon
    &:last-of-type {
      position: relative;
      top: 0.5px;
    }

    &:hover {
      svg {
        path {
          transition: fill 0.3s;
          fill: ${({ theme }) => theme.colors.topNavbar.iconHover};
        }
      }
    }
  }

  svg {
    // YouTube icon
    &:first-of-type {
      width: 17px;
      height: 12px;
    }

    // LinkedIn icon
    &:last-of-type {
      width: 15px;
      height: 14px;
    }

    path {
      fill: ${({ theme, darkBackground }) =>
        darkBackground
          ? theme.colors.topNavbar.iconDarkBg
          : theme.colors.topNavbar.iconLightBg};
    }
  }
`

export const IconLink = styled.a``

export const LanguageSwitcherWrapper = styled.div`
  position: relative;
  top: -1.5px;
`
