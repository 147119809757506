// Next.js
import Link from '../../Link'

// React components
import PhoneContact from '../../PhoneContact'
import LanguageSwitcher from '../../LanguageSwitcher'

// React components (svg)
import YoutubeIcon from '../../svgs/icons/YoutubeIcon'
import LinkedInIcon from '../../svgs/icons/LinkedInIcon'

// Styled Components
import {
  TopNavbarWrapper,
  Container,
  Icons,
  IconLink,
  Nav,
  NavList,
  NavItem,
  NavLink,
  LanguageSwitcherWrapper,
} from './TopNavbar.styles'
import Spacer from '../../../styled-components/shared/Spacer'

// Types
import { TopNavbarProps } from './TopNavbar.types'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'

const TopNavbar = ({
  darkBackground,
  clipBackground,
  serviceList,
  career,
}: TopNavbarProps) => {
  const newsLocale = useDictionaryLine(dictionary.topNavigation.news)
  const annoucementsLocale = useDictionaryLine(
    dictionary.topNavigation.announcements,
  )
  const careerLocale = useDictionaryLine(dictionary.topNavigation.career)
  const mediaLocale = useDictionaryLine(dictionary.topNavigation.media)
  const contactLocale = useDictionaryLine(dictionary.topNavigation.contact)
  const aboutUsLocale = useDictionaryLine(dictionary.career.header.about)

  return (
    <TopNavbarWrapper>
      <PhoneContact clipBackground={clipBackground} />
      <Container>
        {career ? (
          <Nav>
            <NavList>
              <NavItem>
                <NavLink
                  href='https://www.ave.cz/'
                  rel='noopener noreferrer'
                  darkBackground={darkBackground}
                >
                  AVE
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://www.ave.cz/o-spolecnosti'
                  rel='noopener noreferrer'
                  darkBackground={darkBackground}
                >
                  {aboutUsLocale}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='https://www.ave.cz/kontakt'
                  rel='noopener noreferrer'
                  darkBackground={darkBackground}
                >
                  {contactLocale}
                </NavLink>
              </NavItem>
            </NavList>
          </Nav>
        ) : (
          <Nav>
            <NavList>
              <NavItem>
                <Link href='/novinky' passHref legacyBehavior>
                  <NavLink darkBackground={darkBackground}>
                    {newsLocale}
                  </NavLink>
                </Link>
              </NavItem>
              {process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk' && (
                <NavItem>
                  <Link href='/media#oznamy' passHref legacyBehavior>
                    <NavLink darkBackground={darkBackground}>
                      {annoucementsLocale}
                    </NavLink>
                  </Link>
                </NavItem>
              )}
              {process.env.NEXT_PUBLIC_DEFAULT_LOCALE !== 'sk' && (
                <NavItem>
                  <NavLink
                    href='https://kariera.ave.cz/'
                    rel='noopener noreferrer'
                    darkBackground={darkBackground}
                  >
                    {careerLocale}
                  </NavLink>
                </NavItem>
              )}

              <NavItem>
                <Link href='/media' passHref legacyBehavior>
                  <NavLink darkBackground={darkBackground}>
                    {mediaLocale}
                  </NavLink>
                </Link>
              </NavItem>
              <NavItem>
                <Link href='/kontakt' passHref legacyBehavior>
                  <NavLink darkBackground={darkBackground}>
                    {contactLocale}
                  </NavLink>
                </Link>
              </NavItem>
            </NavList>
          </Nav>
        )}
        <Spacer size={18} axis='horizontal' />
        <Icons darkBackground={darkBackground}>
          <IconLink
            href={
              serviceList?.contact?.data?.attributes.social.youtube ||
              'https://www.youtube.com/@aveczodpadovehospodarstvis1865'
            }
            target='_blank'
            rel='noopener noreferrer'
            title='YouTube'
            aria-label='YouTube'
          >
            <YoutubeIcon />
          </IconLink>

          <IconLink
            href={
              serviceList?.contact?.data?.attributes.social.linkedin ||
              'https://www.linkedin.com/company/ave-cz-odpadove-hospodarstvi-sro/'
            }
            target='_blank'
            rel='noopener noreferrer'
            title='LinkedIn'
            aria-label='LinkedIn'
          >
            <LinkedInIcon />
          </IconLink>
        </Icons>
        <Spacer size={6} axis='horizontal' />
        <LanguageSwitcherWrapper>
          <LanguageSwitcher darkBackground={darkBackground} />
        </LanguageSwitcherWrapper>
      </Container>
    </TopNavbarWrapper>
  )
}
export default TopNavbar
