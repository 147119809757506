// React components
import TopNavbar from './TopNavbar'

// Styled Components
import { HeaderWrapper } from './Header.styles'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Types
import { HeaderProps } from './Header.types'

const Header = ({
  darkBackground,
  clipBackground,
  serviceList,
  career,
}: HeaderProps) => {
  return (
    <HeaderWrapper>
      <MediaContextProvider disableDynamicMediaQueries>
        <Media greaterThanOrEqual='lg'>
          <TopNavbar
            career={career}
            serviceList={serviceList}
            clipBackground={clipBackground}
            darkBackground={darkBackground}
          />
        </Media>
      </MediaContextProvider>
    </HeaderWrapper>
  )
}
export default Header
