// Next.js
import Link from '../Link'

// React
import { useEffect, useState } from 'react'

// React components
import Search from './Search'
import HamburgerMenu from './HamburgerMenu'
import NavList from './NavList'
import Submenu from './Submenu'

// React components (svgs)
import AveLogo from '../svgs/logos/AveLogo'
import BurgerIcon from '../svgs/icons/BurgerIcon'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Styled Components
import {
  MainNavbarWrapper,
  MainWrapper,
  IconsContainer,
  HamburgerIconWrapper,
  AveLogoWrapper,
} from './MainNavbar.styles'

// Types
import { MainNavbarProps } from './MainNavbar.types'

// Store
import useStore from '../../utils/store'

const MainNavbar = ({
  darkBackground,
  serviceList,
  career,
}: MainNavbarProps) => {
  const menuOpen = useStore(state => state.menuOpen)
  const openMenu = useStore(state => state.openMenu)
  const submenu = useStore(state => state.submenu)
  const [isDarkBackground, setIsDarkBackground] = useState(darkBackground)

  useEffect(() => {
    //Set overflow: hidden to body when menu open to prevent background scrolling
    if (menuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [menuOpen])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 120) {
          setIsDarkBackground(false)
        } else {
          setIsDarkBackground(darkBackground)
        }
      })
    }
  }, [])

  return (
    <MainWrapper>
      <Media lessThan='lg'>
        {menuOpen && (
          <HamburgerMenu career={career} serviceList={serviceList} />
        )}
      </Media>
      <MainNavbarWrapper darkBackground={isDarkBackground}>
        <Link href='/' passHref legacyBehavior>
          <AveLogoWrapper aria-label='AVE logo'>
            <AveLogo />
          </AveLogoWrapper>
        </Link>
        <IconsContainer>
          <MediaContextProvider disableDynamicMediaQueries>
            <Media lessThan='lg'>
              <HamburgerIconWrapper onClick={openMenu}>
                <BurgerIcon color={isDarkBackground ? 'white' : 'red'} />
              </HamburgerIconWrapper>
            </Media>
            <Media greaterThanOrEqual='lg'>
              <NavList career={career} darkBackground={isDarkBackground} />
            </Media>
          </MediaContextProvider>
          <Search darkBackground={isDarkBackground} />
        </IconsContainer>
      </MainNavbarWrapper>
      <Media greaterThanOrEqual='lg'>
        {submenu && <Submenu serviceList={serviceList} />}
      </Media>
    </MainWrapper>
  )
}
export default MainNavbar
