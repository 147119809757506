import styled, { css } from 'styled-components'

// Types
import { LinkProps } from './PhoneContact.types'

export const PhoneContactWrapper = styled.div``

export const Link = styled.a<LinkProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.phoneContact.text};

  &:hover {
    color: ${({ theme }) => theme.colors.phoneContact.textHover};
    font-weight: 700;
    text-decoration: none;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.phoneContact.textHover};
      }
    }
  }

  ${({ clipBackground, theme }) =>
    clipBackground &&
    css`
      @media (min-width: 1820px) {
        color: ${theme.colors.white};

        svg {
          path {
            stroke: ${theme.colors.white};
          }
        }

        &:hover {
          color: ${theme.colors.white};

          svg {
            path {
              stroke: ${theme.colors.white};
            }
          }
        }
      }
    `}
`

export const LinkText = styled.span`
  font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
`
