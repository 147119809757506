const SearchIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21.721'
      height='21.721'
      viewBox='0 0 21.721 21.721'
    >
      <g
        fill='none'
        stroke='#fff'
        strokeWidth='1.5'
        data-name='Group 409'
        transform='translate(-1617.06 -57.31)'
      >
        <g data-name='Ellipse 8' transform='rotate(-45 889.153 -1918.57)'>
          <circle cx='6.703' cy='6.703' r='6.703' stroke='none'></circle>
          <circle cx='6.703' cy='6.703' r='5.953'></circle>
        </g>
        <path
          d='M0 0L0 10.646'
          data-name='Line 7'
          transform='rotate(-45 901.032 -1932.97)'
        ></path>
      </g>
    </svg>
  )
}
export default SearchIcon
