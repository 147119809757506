// React components (svg)
import RightArrowIcon from '../../../svgs/icons/RightArrowIcon'

// Styled Components
import {
  SubNavListWrapper,
  Nav,
  NavList,
  // NavItem,
  // NavLink,
  BackLink,
  ArrowIconWrapper,
} from './SubNavList.styles'

// Types
import { SubNavListProps } from './SubNavList.types'

// Store
import useStore from '../../../../utils/store'

const SubNavList = ({ backText, children }: SubNavListProps) => {
  const setSubNav = useStore(state => state.setSubNav)

  return (
    <SubNavListWrapper
      initial={{
        x: '100%',
      }}
      animate={{
        x: 0,
        transition: {
          duration: 0.4,
        },
      }}
      exit={{
        x: '100%',
      }}
    >
      <BackLink
        onClick={() => {
          setSubNav('')
        }}
      >
        <ArrowIconWrapper>
          <RightArrowIcon />
        </ArrowIconWrapper>
        {backText}
      </BackLink>
      <Nav>
        <NavList>{children}</NavList>
      </Nav>
    </SubNavListWrapper>
  )
}
export default SubNavList
