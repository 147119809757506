import styled, { css } from 'styled-components'

// Types
import {
  PageTitleProps,
  TitleProps,
  MainContainerProps,
  DividerProps,
  InputWrapperProps,
  InputProps,
  TextAreaProps,
  SmallTextProps,
} from './common.types'

export const PageTitle = styled.h1<PageTitleProps>`
  font-weight: 700;
  text-align: ${({ align }) => (align ? align : 'left')};
  color: ${({ theme }) => theme.colors.pageTitle.text};
  font-size: ${({ theme }) => (40 - 2) / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme }) => (50 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    padding-left: 240px;
  }
`

export const ThinText = styled.span`
  font-weight: 200;
`

export const LightText = styled.span`
  font-weight: 300;
`

export const NormalText = styled.span`
  font-weight: 400;
`

export const BoldText = styled.span`
  font-weight: 700;
`
// To render as different HTML element (h3, h4, h5,...) use as prop (e.g. as="h3")
export const Title = styled.h2<TitleProps>`
  font-weight: 700;
  text-align: ${({ align }) => (align ? align : 'left')};
  color: ${({ theme, type }) =>
    !type || type === 'grey'
      ? theme.colors.title.grey
      : theme.colors.title.red};
  font-size: ${({ theme, type }) =>
    !type || type === 'grey'
      ? (25 - 2) / theme.fontSizes.root + 'rem'
      : (20 - 2) / theme.fontSizes.root + 'rem'};

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    font-size: ${({ theme, type }) =>
      !type || type === 'grey'
        ? (39 - 2) / theme.fontSizes.root + 'rem'
        : (25 - 2) / theme.fontSizes.root + 'rem'};
  }
`

export const MainContainer = styled.div<MainContainerProps>`
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems && alignItems};
  padding: 0 36px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    padding: 0 90px;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    padding: 0 120px;
  }
`

export const Divider = styled.div<DividerProps>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.divider};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ center }) =>
    center &&
    css`
      margin: 0 auto;
    `}
`

export const ContentWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 18px;
  }

  ul,
  ol {
    margin-bottom: 18px;

    li::before {
      content: '– ';
      color: ${({ theme }) => theme.colors.red100};
    }
  }

  .image {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (${({ theme }) => theme.mediaQueries.md}) {
    margin-bottom: 24px;

    .image.image-style-side {
      padding: 0 15px;
      float: right;
    }
  }

  @media (max-width: 920px) {
    .image.image-style-side,
    .image_resized {
      width: 50% !important;
    }
  }

  @media (${({ theme }) => theme.mediaQueries.mdMax}) {
    .image.image-style-side,
    .image_resized {
      width: 100% !important;
    }
  }
`

export const PageContentWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 24px;
  }

  h2 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.red100};
    font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};

    @media (${({ theme }) => theme.mediaQueries.lg}) {
      font-size: ${({ theme }) => (25 - 2) / theme.fontSizes.root + 'rem'};
    }
  }

  p {
    margin-bottom: 18px;
  }

  ul,
  ol {
    margin-bottom: 18px;

    li::before {
      content: '– ';
      color: ${({ theme }) => theme.colors.red100};
    }
  }
`

export const Video = styled.video`
  width: 384px;
  height: 234px;
  min-width: 384px;
  min-height: 234px;
  position: relative;
  display: block;
`

export const VideoCaption = styled.div`
  color: ${({ theme }) => theme.colors.grey120};
  font-weight: 700;
`

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  max-width: 398px;
  width: 100%;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        position: absolute;
        top: 0;
        right: -5px;
      }
    `}
`

export const Input = styled.input<InputProps>`
  min-height: 54px;
  display: block;
  color: ${({ theme }) => theme.colors.contactForm.input.text};
  background-color: ${({ theme, promo }) =>
    promo
      ? theme.colors.contactForm.input.backgroundPromo
      : theme.colors.contactForm.input.background};
  border: none;
  margin-bottom: 12px;
  padding: 15px 22px;
  border-radius: 66px;
  width: 100%;
  max-width: 398px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.contactForm.input.placeholder};
  }
`

export const TextArea = styled.textarea<TextAreaProps>`
  min-height: 54px;
  display: block;
  color: ${({ theme }) => theme.colors.contactForm.input.text};
  background-color: ${({ theme, promo }) =>
    promo
      ? theme.colors.contactForm.input.backgroundPromo
      : theme.colors.contactForm.input.background};
  border: none;
  margin-bottom: 12px;
  padding: 15px 22px;
  border-radius: 33px;
  width: 100%;
  max-width: 398px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.contactForm.input.placeholder};
  }
`

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.contactForm.input.errorText};
  padding: 0 22px 12px;
  font-size: ${({ theme }) => 12 / theme.fontSizes.root + 'rem'};
`

export const SmallText = styled.p<SmallTextProps>`
  font-size: ${({ theme }) => 15 / theme.fontSizes.root + 'rem'};
  color: ${({ theme, error }) =>
    error ? theme.colors.contactForm.input.errorText : theme.colors.body};
`
