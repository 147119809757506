// Next.js
import Link from '../Link'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// React components
import SocialIconsBar from '../SocialIconsBar'

// React components (svgs)
import AveLogo from '../svgs/logos/AveLogo'
import YoutubeIcon from '../svgs/icons/YoutubeIcon'
import LinkedInIcon from '../svgs/icons/LinkedInIcon'

// Styled Components
import {
  FooterWrapper,
  MainContainer,
  LogoWrapper,
  Container,
  HelplineText,
  FooterLink,
  CopyText,
  SocialIconsBarWrapper,
  DesktopContainer,
  InfoContainer,
} from './Footer.styles'
import useDictionaryLine from '../../hooks/useDictionary'
import dictionary from '../../utils/dictionary'
import { Api } from '../../types/api'

type Props = {
  serviceList?: Api.ServiceList
}

const year = new Date()?.getFullYear()

const Footer = ({ serviceList }: Props) => {
  const infoLocale = useDictionaryLine(dictionary.footer.infoLink)
  const dataProtecttionLocale = useDictionaryLine(
    dictionary.footer.dataProtection,
  )
  const whistleblowingLocale = useDictionaryLine(
    dictionary.footer.whistleblowing,
  )
  const cookiesLocale = useDictionaryLine(dictionary.footer.cookies)

  const icons = [
    {
      icon: <YoutubeIcon />,
      href:
        serviceList?.contact?.data?.attributes.social.youtube ||
        'https://www.youtube.com/@aveczodpadovehospodarstvis1865',
    },
    {
      icon: <LinkedInIcon />,
      href:
        serviceList?.contact?.data?.attributes.social.linkedin ||
        'https://www.linkedin.com/company/ave-cz-odpadove-hospodarstvi-sro/',
    },
  ].filter(icon => icon.href) as any

  return (
    <FooterWrapper>
      <MainContainer>
        <MediaContextProvider disableDynamicMediaQueries>
          <Media lessThan='md'>
            <Container>
              <LogoWrapper>
                <AveLogo />
              </LogoWrapper>
              <SocialIconsBar icons={icons} />
            </Container>
            <HelplineText>
              {infoLocale}:{' '}
              {process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk' ? (
                <a href='tel:+421259301071' title='+421 259 301 071'>
                  +421 259 301 071
                </a>
              ) : (
                <a href='tel:+420800118800' title='+420 800 118 800'>
                  800 118 800
                </a>
              )}
            </HelplineText>
            <Link href='/ochrana-udaju' passHref legacyBehavior>
              <FooterLink>{dataProtecttionLocale}</FooterLink>
            </Link>
            <Link
              href='/oznamovani-protipravniho-jednani'
              passHref
              legacyBehavior
            >
              <FooterLink>{whistleblowingLocale}</FooterLink>
            </Link>
            <Link href='/informace-o-cookies' passHref legacyBehavior>
              <FooterLink>{cookiesLocale}</FooterLink>
            </Link>
            <CopyText>
              &copy; {year} {serviceList?.contact.data.attributes.title}
            </CopyText>
          </Media>
          <Media greaterThanOrEqual='md'>
            <SocialIconsBarWrapper>
              <SocialIconsBar icons={icons} />
            </SocialIconsBarWrapper>
            <DesktopContainer>
              <LogoWrapper>
                <AveLogo />
              </LogoWrapper>
              <InfoContainer>
                <HelplineText>
                  {infoLocale}: <br />
                  {process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk' ? (
                    <a href='tel:+421259301071' title='+421 259 301 071'>
                      +421 259 301 071
                    </a>
                  ) : (
                    <a href='tel:+420800118800' title='+420 800 118 800'>
                      800 118 800
                    </a>
                  )}
                </HelplineText>
                <Link href='/ochrana-udaju' passHref legacyBehavior>
                  <FooterLink>{dataProtecttionLocale}</FooterLink>
                </Link>
                <Link
                  href='/oznamovani-protipravniho-jednani'
                  passHref
                  legacyBehavior
                >
                  <FooterLink>{whistleblowingLocale}</FooterLink>
                </Link>
                <Link href='/informace-o-cookies' passHref legacyBehavior>
                  <FooterLink>{cookiesLocale}</FooterLink>
                </Link>
              </InfoContainer>
            </DesktopContainer>
            <CopyText>
              &copy; {year} {serviceList?.contact.data.attributes.title}
            </CopyText>
          </Media>
        </MediaContextProvider>
      </MainContainer>
    </FooterWrapper>
  )
}
export default Footer
