// Styled Components
import { AveLogoWrapper } from './AveLogo.styles'

const AveLogo = () => {
  return (
    <AveLogoWrapper
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 138.844 72.767'
    >
      <g data-name='Group 646'>
        <path
          fill='#fff'
          d='M17.338 4.916A7.065 7.065 0 0123.774 0H134.92a3.694 3.694 0 013.75 4.915l-17.235 62.937a7.073 7.073 0 01-6.441 4.915H3.928a3.7 3.7 0 01-3.755-4.916z'
          data-name='Path 372'
        ></path>
        <path
          fill='#d9000d'
          d='M27.819 11.225A2.607 2.607 0 0130.2 9.4h31.223a1.375 1.375 0 011.4 1.823l-16.8 62.347a2.606 2.606 0 01-2.378 1.822H12.419a1.375 1.375 0 01-1.4-1.822z'
          data-name='Path 373'
          transform='translate(-6.952 -5.964)'
        ></path>
        <path
          fill='#d9000d'
          d='M135.04 11.225a2.607 2.607 0 012.378-1.825h31.226a1.375 1.375 0 011.4 1.823l-16.8 62.347a2.606 2.606 0 01-2.378 1.822h-31.227a1.375 1.375 0 01-1.4-1.822z'
          data-name='Path 374'
          transform='translate(-74.964 -5.964)'
        ></path>
        <path
          fill='#d9000d'
          d='M242.772 11.225a2.607 2.607 0 012.379-1.825h31.226a1.375 1.375 0 011.4 1.823l-16.8 62.347a2.606 2.606 0 01-2.378 1.822h-31.228a1.375 1.375 0 01-1.4-1.822z'
          data-name='Path 375'
          transform='translate(-143.3 -5.964)'
        ></path>
        <path
          fill='#fff'
          d='M49.663 54.94a.628.628 0 01.5-.3H56.4a.378.378 0 01.369.338l2.81 32.231a.307.307 0 01-.31.339h-5.253a.378.378 0 01-.369-.339l-.5-5.772a.378.378 0 00-.369-.338H41.912a.627.627 0 00-.5.3l-3.14 5.851a.627.627 0 01-.5.3H32.42a.186.186 0 01-.178-.3zm-5.809 21.321a.19.19 0 00.184.3h8.67a.316.316 0 00.318-.339l-.868-13.482a8.519 8.519 0 01-.01-.678l.077-2.08c.007-.186-.049-.2-.124-.028l-.955 2.183c-.075.171-.206.447-.292.613z'
          data-name='Path 376'
          transform='translate(-20.428 -34.66)'
        ></path>
        <path
          fill='none'
          stroke='#fff'
          strokeMiterlimit='10'
          strokeWidth='1.639'
          d='M29.235 20.28a.628.628 0 01.5-.3h6.237a.378.378 0 01.369.338l2.81 32.231a.307.307 0 01-.31.339h-5.253a.378.378 0 01-.369-.339l-.5-5.772a.378.378 0 00-.369-.338H21.484a.627.627 0 00-.5.3l-3.14 5.851a.627.627 0 01-.5.3h-5.352a.186.186 0 01-.178-.3zm-5.809 21.321a.19.19 0 00.184.3h8.67a.316.316 0 00.318-.339L31.73 28.08a8.519 8.519 0 01-.01-.678l.077-2.08c.007-.186-.049-.2-.124-.028l-.955 2.183c-.075.171-.206.447-.292.613z'
          data-name='Path 377'
        ></path>
        <path
          fill='#fff'
          d='M172.747 87.231a.635.635 0 01-.5.3h-5.117a.382.382 0 01-.372-.338l-3.082-32.276a.3.3 0 01.307-.338h5.3a.371.371 0 01.363.339l1.525 21.945c.013.186.02.491.016.678l-.063 2.871c0 .186.052.2.123.026l1.217-2.923c.072-.173.2-.449.289-.614l11.619-22.022a.621.621 0 01.5-.3h5.49a.184.184 0 01.176.3z'
          data-name='Path 378'
          transform='translate(-103.82 -34.619)'
        ></path>
        <path
          fill='none'
          stroke='#fff'
          strokeMiterlimit='10'
          strokeWidth='1.639'
          d='M68.927 52.612a.635.635 0 01-.5.3H63.31a.382.382 0 01-.372-.338l-3.082-32.276a.3.3 0 01.307-.338h5.3a.371.371 0 01.363.339l1.525 21.945c.013.186.02.491.016.678l-.063 2.871c0 .186.052.2.123.026l1.217-2.923c.072-.173.2-.449.289-.614L80.552 20.26a.621.621 0 01.5-.3h5.49a.184.184 0 01.176.3z'
          data-name='Path 379'
        ></path>
        <path
          fill='#fff'
          d='M287.6 54.577a.245.245 0 01.248.327l-1.069 3.833a.475.475 0 01-.431.327h-14.959a.473.473 0 00-.43.328l-2.452 8.881a.246.246 0 00.249.328h12.477a.245.245 0 01.248.327l-1.038 3.692a.475.475 0 01-.431.327h-12.443a.472.472 0 00-.43.328l-2.517 9.206a.246.246 0 00.25.328h15a.243.243 0 01.246.327l-1.09 3.836a.478.478 0 01-.433.327h-21.04a.246.246 0 01-.25-.328L266.09 54.9a.471.471 0 01.429-.328z'
          data-name='Path 380'
          transform='translate(-163.206 -34.619)'
        ></path>
        <path
          fill='none'
          stroke='#fff'
          strokeMiterlimit='10'
          strokeWidth='1.639'
          d='M124.394 19.958a.245.245 0 01.248.327l-1.069 3.833a.475.475 0 01-.431.327h-14.959a.473.473 0 00-.43.328l-2.452 8.881a.246.246 0 00.249.328h12.477a.245.245 0 01.248.327l-1.038 3.692a.475.475 0 01-.431.327h-12.443a.472.472 0 00-.43.328l-2.517 9.206a.246.246 0 00.25.328h15a.243.243 0 01.246.327l-1.09 3.836a.478.478 0 01-.433.327h-21.04a.246.246 0 01-.25-.328l8.785-32.071a.471.471 0 01.429-.328z'
          data-name='Path 381'
        ></path>
      </g>
    </AveLogoWrapper>
  )
}
export default AveLogo
