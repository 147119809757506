import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Types
import {
  SearchWrapperProps,
  InputProps,
  SearchIconWrapperProps,
} from './Search.types'

export const SearchWrapper = styled(motion.div)<SearchWrapperProps>`
  position: relative;
  cursor: ${({ open }) => !open && 'pointer'};
`
export const Label = styled.label`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`

export const Input = styled.input<InputProps>`
  display: block;
  width: 100%;
  border-radius: inherit;
  padding: ${({ open }) => (open ? '6px 32px 6px 18px' : '6px')};
  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};

  &:focus {
    outline: none;
  }

  ${({ darkBackground }) =>
    darkBackground
      ? css`
          color: ${({ theme }) => theme.colors.search.textDarkBg};
          border: 1px solid ${({ theme }) => theme.colors.search.borderDarkBg};
          background-color: ${({ theme, open }) =>
            open
              ? theme.colors.search.backgroundOpenDarkBg
              : theme.colors.search.backgroundClosedDarkBg};
          pointer-events: ${({ open }) => !open && 'none'};

          &::placeholder {
            color: ${({ theme }) => theme.colors.search.textPlaceholderDarkBg};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.search.textLightBg};
          border: 1px solid ${({ theme }) => theme.colors.search.borderLightBg};
          background-color: ${({ theme, open }) =>
            open
              ? theme.colors.search.backgroundOpenLightBg
              : theme.colors.search.backgroundClosedLightBg};
          pointer-events: ${({ open }) => !open && 'none'};

          &::placeholder {
            color: ${({ theme }) => theme.colors.search.textPlaceholderLightBg};
          }
        `}
`

export const SearchIconWrapper = styled.div<SearchIconWrapperProps>`
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 2;

  svg > g {
    stroke: ${({ open, theme, darkBackground }) =>
      open
        ? darkBackground
          ? theme.colors.search.iconOpenDarkBg
          : theme.colors.search.iconOpenLightBg
        : darkBackground
        ? theme.colors.search.iconClosedDarkBg
        : theme.colors.search.iconClosedLightBg};
  }
`
