import styled, { css } from 'styled-components'

type SpacerProps = {
  axis?: string
  size: number
  when?: {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
    xxl?: number
    xxxl?: number
  }
}

function getWidth(size: number, axis?: string | undefined) {
  return axis === 'vertical' ? 1 : size
}

function getHeight(size: number, axis?: string | undefined) {
  return axis === 'horizontal' ? 1 : size
}
const Spacer = styled.span<SpacerProps>`
  display: block;
  width: ${({ size, axis }) => getWidth(size, axis)}px;
  min-width: ${({ size, axis }) => getWidth(size, axis)}px;
  height: ${({ size, axis }) => getHeight(size, axis)}px;
  min-height: ${({ size, axis }) => getHeight(size, axis)}px;

  ${({ theme, axis, when }) =>
    when &&
    when.xs &&
    css`
      @media (${theme.mediaQueries.xs}) {
        width: ${getWidth(when.xs, axis)}px;
        min-width: ${getWidth(when.xs, axis)}px;
        height: ${getHeight(when.xs, axis)}px;
        min-height: ${getHeight(when.xs, axis)}px;
      }
    `}

  ${({ theme, axis, when }) =>
    when &&
    when.sm &&
    css`
      @media (${theme.mediaQueries.sm}) {
        width: ${getWidth(when.sm, axis)}px;
        min-width: ${getWidth(when.sm, axis)}px;
        height: ${getHeight(when.sm, axis)}px;
        min-height: ${getHeight(when.sm, axis)}px;
      }
    `}

    ${({ theme, axis, when }) =>
    when &&
    when.md &&
    css`
      @media (${theme.mediaQueries.md}) {
        width: ${getWidth(when.md, axis)}px;
        min-width: ${getWidth(when.md, axis)}px;
        height: ${getHeight(when.md, axis)}px;
        min-height: ${getHeight(when.md, axis)}px;
      }
    `}

    ${({ theme, axis, when }) =>
    when &&
    when.lg &&
    css`
      @media (${theme.mediaQueries.lg}) {
        width: ${getWidth(when.lg, axis)}px;
        min-width: ${getWidth(when.lg, axis)}px;
        height: ${getHeight(when.lg, axis)}px;
        min-height: ${getHeight(when.lg, axis)}px;
      }
    `}

    ${({ theme, axis, when }) =>
    when &&
    when.xl &&
    css`
      @media (${theme.mediaQueries.xl}) {
        width: ${getWidth(when.xl, axis)}px;
        min-width: ${getWidth(when.xl, axis)}px;
        height: ${getHeight(when.xl, axis)}px;
        min-height: ${getHeight(when.xl, axis)}px;
      }
    `}

    ${({ theme, axis, when }) =>
    when &&
    when.xxl &&
    css`
      @media (${theme.mediaQueries.xxl}) {
        width: ${getWidth(when.xxl, axis)}px;
        min-width: ${getWidth(when.xxl, axis)}px;
        height: ${getHeight(when.xxl, axis)}px;
        min-height: ${getHeight(when.xxl, axis)}px;
      }
    `}

    ${({ theme, axis, when }) =>
    when &&
    when.xxxl &&
    css`
      @media (${theme.mediaQueries.xxxl}) {
        width: ${getWidth(when.xxxl, axis)}px;
        min-width: ${getWidth(when.xxxl, axis)}px;
        height: ${getHeight(when.xxxl, axis)}px;
        min-height: ${getHeight(when.xxxl, axis)}px;
      }
    `}
`
export default Spacer
