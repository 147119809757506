import styled from 'styled-components'

export const YoutubeIconWrapper = styled.svg`
  height: 16px;
  position: relative;
  bottom: 0.5px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    height: 23px;
  }
`
