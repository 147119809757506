// Next.js
import Link from '../Link'

// React components (svg)
import PhoneIcon from '../svgs/icons/PhoneIcon'

// Styled Components
import {
  PhoneContactWrapper,
  Link as StyledLink,
  LinkText,
} from './PhoneContact.styles'

// Types
import { PhoneContactProps } from './PhoneContact.types'

const PhoneContact = ({ clipBackground }: PhoneContactProps) => {
  return (
    <PhoneContactWrapper>
      {process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'sk' ? (
        <Link href='tel:+421259301071' passHref legacyBehavior>
          <StyledLink clipBackground={clipBackground} title='+421 259 301 071'>
            <PhoneIcon />
            <LinkText>+421 259 301 071</LinkText>
          </StyledLink>
        </Link>
      ) : (
        <Link href='tel:+420800118800' passHref legacyBehavior>
          <StyledLink clipBackground={clipBackground} title='+420 800 118 800'>
            <PhoneIcon />
            <LinkText>800 118 800</LinkText>
          </StyledLink>
        </Link>
      )}
    </PhoneContactWrapper>
  )
}
export default PhoneContact
