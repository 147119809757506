import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

export const SubNavListWrapper = styled(motion.div)``

export const Nav = styled.nav``

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
`

export const NavItem = styled.li`
  &:first-of-type {
    a {
      border-top: 1px solid
        ${({ theme }) => theme.colors.hamburgerMenu.navList.subItem.border};
    }
  }
  &:last-of-type {
    a {
      border-bottom: none;
    }
  }
`

export const NavLink = styled.a`
  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};
  cursor: pointer;
  display: block;
  padding: 18px 0;
  color: ${({ theme }) => theme.colors.hamburgerMenu.navList.subItem.text};
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.hamburgerMenu.navList.subItem.border};
  text-decoration: none;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.hamburgerMenu.navList.subItem.text};
  }
`

export const BackLink = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => (20 - 2) / theme.fontSizes.root + 'rem'};
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 18px 0;
  color: ${({ theme }) =>
    theme.colors.hamburgerMenu.navList.subItem.backLink.text};
`

export const ArrowIconWrapper = styled.div`
  transform: rotate(180deg);
`
