import styled, { css } from 'styled-components'

// Types
import { LayoutWrapperProps } from './Layout.types'

export const LayoutWrapper = styled.div<LayoutWrapperProps>`
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ background }) => background};

  ${({ gradientBg }) =>
    gradientBg &&
    css`
      @media (${({ theme }) => theme.mediaQueries.lg}) {
        background: ${({ gradientBg, background, theme }) =>
          gradientBg ? theme.gradients.grey86ToGrey80 : background};

        &::before {
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
          content: '';
          width: 30%;
          height: 40%;
          background: ${({ theme }) => theme.colors.white};
          clip-path: polygon(0 0, 0 95%, 35% 0);
        }

        &::after {
          position: absolute;
          z-index: 0;
          right: 0;
          bottom: 0;
          content: '';
          width: 30%;
          height: 50%;
          background: ${({ theme }) => theme.colors.white};
          clip-path: polygon(100% 100%, 79% 100%, 100% 38%);
        }
      }

      @media (${({ theme }) => theme.mediaQueries.xl}) {
        &::before {
          width: 100%;
          height: 100%;
          bottom: 0;
          top: initial;
        }

        &::after {
          width: 95%;
          height: 80%;
        }
      }
    `}

  ${({ clipBg }) =>
    clipBg &&
    css`
      @media (min-width: 1820px) {
        max-width: 1920px;
        margin: 0 auto;
        &::before {
          position: absolute;
          z-index: 0;
          left: 0;
          top: 0;
          content: '';
          max-width: 565px;
          max-height: min(1960px, 80%);
          width: 100%;
          height: 100%;
          clip-path: polygon(0 0, 0% 100%, 100% 0);
          background: linear-gradient(
              rgba(33, 33, 33, 0.4) 0%,
              rgba(196, 192, 192, 0.4) 59.93%,
              rgba(196, 196, 196, 0.4) 67.59%,
              rgba(0, 0, 0, 0.4) 100%
            ),
            url('/images/article-placeholder.webp') center top / cover no-repeat;
        }
      }
    `}
`

export const Main = styled.main`
  position: relative;
  z-index: 2;
`
