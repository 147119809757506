// Next.js
import { useRouter } from '../../hooks/router'
import { test, compose, filter, map, keys, identity, reduce, assoc, mapObjIndexed, values, split, head } from 'ramda'

// React
import { useState } from 'react'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// React components (svgs)
import ChevronIcon from '../svgs/icons/ChevronIcon'

// Styled Components
import {
  LanguageSwitcherWrapper,
  Button,
  List,
  ListItem,
} from './LanguageSwitcher.styles'

// Helpers
import { formatLocale } from '../../utils/helpers'

// Types
import { LanguageSwitcherProps } from './LanguageSwitcher.types'
import translateMap from '../../translateMap'

const flipKeysAndValue = compose(
  reduce((a, b: object) => ({ ...a, ...b }), {}),
  map((vals: any) => assoc(vals[1], vals[0], {})),
  values,
  mapObjIndexed((value, key) => [key, value])
)

const reversedTranslateMap = flipKeysAndValue(translateMap)

export const getLangTranslate = (translateMap: any) => (pathname: string): string => {
  // @ts-ignore
  return compose<any, any, any, any>(
    matches => matches[0]
      // @ts-ignore
      ? `${translateMap[matches[0]]}${pathname.replace(matches[0], '')}`
      : pathname,
    filter(identity as any),
    map((url: string) => test(new RegExp(url), pathname) && url),
    // @ts-ignore
    keys,
  )(translateMap)
}
// @ts-ignore
const getFirstUri: (u: string) => string = compose(
  k => `/${k || ''}`,
  head,
  // @ts-ignore
  filter(a => a),
  split('/'),
)

const LanguageSwitcher = ({ darkBackground }: LanguageSwitcherProps) => {
  const router = useRouter()
  const { pathname, asPath, query, locales, locale } = router
  const [isOpen, setIsOpen] = useState(false)

  return (
    <LanguageSwitcherWrapper
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Button aria-label='Přepínač jazyků' darkBackground={darkBackground}>
        {formatLocale(locale)} <ChevronIcon />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <List
            initial={{
              opacity: 0,
              rotateX: 30,
            }}
            animate={{
              opacity: 1,
              rotateX: 0,
              transition: {
                duration: 0.45,
              },
            }}
            exit={{
              opacity: 0,
              rotateX: -30,
              transition: {
                duration: 0.45,
                delay: 0.1,
              },
            }}
          >
            {locales?.map((locale, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={() => {
                    const translate = getLangTranslate(locale === 'en' ? translateMap : reversedTranslateMap)(getFirstUri(pathname.replace('/career', '')))

                    // console.log(pathname, locale, translate)
                    // router.push({ pathname: translate, query }, asPath, { locale, shallow: true })
                    window.location.href = `${locale === 'en' ? '/en' : ''}${translate.replace('/services', '/').replace('/sluzby', '/')}${window.location.search}`
                  }}
                >
                  {formatLocale(locale)}
                </ListItem>
              )
            })}
          </List>
        )}
      </AnimatePresence>
    </LanguageSwitcherWrapper>
  )
}
export default LanguageSwitcher
