import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

// Types
import { ButtonProps } from './LanguageSwitcher.types'

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 10px;
  border: none;
  border-radius: 20px;
  color: ${({ theme, darkBackground }) =>
    darkBackground
      ? theme.colors.languageSwitcher.button.textDarkBg
      : theme.colors.languageSwitcher.button.textLightBg};
  background-color: ${({ theme }) =>
    theme.colors.languageSwitcher.button.background};
  transition: background-color 0.6s;

  svg {
    position: relative;
    top: -3px;

    path {
      stroke: ${({ theme, darkBackground }) =>
        darkBackground
          ? theme.colors.languageSwitcher.button.textDarkBg
          : theme.colors.languageSwitcher.button.textLightBg};
    }
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.languageSwitcher.button.backgroundHover};
    color: ${({ theme, darkBackground }) =>
      darkBackground
        ? theme.colors.languageSwitcher.button.textDarkBgHover
        : theme.colors.languageSwitcher.button.textLightBgHover};

    svg {
      path {
        stroke: ${({ theme, darkBackground }) =>
          darkBackground
            ? theme.colors.languageSwitcher.button.textDarkBgHover
            : theme.colors.languageSwitcher.button.textLightBgHover};
      }
    }
  }

  &:focus {
    outline-offset: 2px;
  }
`

export const LanguageSwitcherWrapper = styled.div`
  isolation: isolate;
  position: relative;
  max-width: 64px;
  z-index: 35;

  &:hover > ${Button} {
    background-color: ${({ theme }) =>
      theme.colors.languageSwitcher.button.backgroundHover};
    color: ${({ theme, darkBackground }) =>
      darkBackground
        ? theme.colors.languageSwitcher.button.textDarkBgHover
        : theme.colors.languageSwitcher.button.textLightBgHover};

    svg {
      path {
        stroke: ${({ theme, darkBackground }) =>
          darkBackground
            ? theme.colors.languageSwitcher.button.textDarkBgHover
            : theme.colors.languageSwitcher.button.textLightBgHover};
      }
    }
  }
`

export const List = styled(motion.ul)`
  position: absolute;
  top: 18px;
  z-index: 2;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 24px;
  padding-bottom: 12px;
  max-width: 60px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
  border-radius: 9px;
  background-color: ${({ theme }) =>
    theme.colors.languageSwitcher.list.background};
`

export const ListItem = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 6px;
  border-radius: 57px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.languageSwitcher.list.item.backgroundHover};
  }
`
