// Next.js
import Head from 'next/head'
import { useRouter } from 'next/router'

// Types
import { SeoProps } from './Seo.types'
import metaImage from '../../public/images/ave-main.webp'

const Seo = ({
  title = 'AVE',
  description = 'Jsme jednou z největších společností na trhu odpadového hospodářství, komunálních služeb, sanací ekologických zátěží, demolic, údržby komunikací a facility managementu.',
  ogType = 'website',
  image = `https://${process.env.NEXT_PUBLIC_IS_CAREER ? 'kariera.' : ''}${
    process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'ave.cz' : 'ave.sk'
  }${metaImage.src}`,
  robots,
}: SeoProps) => {
  const router = useRouter()

  return (
    <Head>
      <title>{`${title ? title : 'AVE'} | ${
        process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'AVE CZ' : 'AVE SK'
      } `}</title>
      <meta
        name='description'
        content={
          description
            ? description
            : 'Jsme jednou z největších společností na trhu odpadového hospodářství, komunálních služeb, sanací ekologických zátěží, demolic, údržby komunikací a facility managementu.'
        }
      />

      <meta name='twitter:card' content='summary' />
      <meta
        name='twitter:title'
        content={`${title ? title : 'AVE'} | ${
          process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'AVE CZ' : 'AVE SK'
        } `}
      />
      <meta
        name='twitter:description'
        content={
          description
            ? description
            : 'Jsme jednou z největších společností na trhu odpadového hospodářství, komunálních služeb, sanací ekologických zátěží, demolic, údržby komunikací a facility managementu.'
        }
      />
      <meta
        name='twitter:image'
        content={
          image
            ? image
            : `https://${process.env.NEXT_PUBLIC_IS_CAREER ? 'kariera.' : ''}${
                process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'ave.cz' : 'ave.sk'
              }${metaImage.src}`
        }
      />

      <meta
        property='og:title'
        content={`${title ? title : 'AVE'} | ${
          process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'AVE CZ' : 'AVE SK'
        } `}
      />
      <meta
        property='og:description'
        content={
          description
            ? description
            : 'Jsme jednou z největších společností na trhu odpadového hospodářství, komunálních služeb, sanací ekologických zátěží, demolic, údržby komunikací a facility managementu.'
        }
      />
      <meta
        property='og:image'
        content={
          image
            ? image
            : `https://${process.env.NEXT_PUBLIC_IS_CAREER ? 'kariera.' : ''}${
                process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'ave.cz' : 'ave.sk'
              }${metaImage.src}`
        }
      />
      <meta property='og:type' content={ogType ? ogType : 'website'} />
      <meta
        property='og:url'
        content={`https://${
          process.env.NEXT_PUBLIC_IS_CAREER ? 'kariera.' : ''
        }${process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'ave.cz' : 'ave.sk'}${
          router.asPath
        }`}
      />
      <meta
        property='og:site_name'
        content={`${
          process.env.NEXT_PUBLIC_DEFAULT_LOCALE ? 'AVE.cz' : 'AVE.sk'
        }${process.env.NEXT_PUBLIC_IS_CAREER ? ' - kariéra' : ''}`}
      />
      {robots && <meta name='robots' content={robots} />}
    </Head>
  )
}
export default Seo
