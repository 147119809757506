// Next.js
import { useRouter } from '../../../../hooks/router'

// React
import { useState, useRef } from 'react'

// React components (svg)
import ChevronIcon from '../../../svgs/icons/ChevronIcon'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// Styled Components
import {
  LanguageSwitcherWrapper,
  Button,
  List,
  ListItem,
} from './LanguageSwitcher.styles'

// Hooks
import useClickOutside from '../../../../hooks/useClickOutside'

// Helpers
import { formatLocale } from '../../../../utils/helpers'

const LanguageSwitcher = () => {
  const ref = useRef(null)
  const router = useRouter()
  const { pathname, asPath, query, locales, locale } = router
  const [isOpen, setIsOpen] = useState(false)

  useClickOutside(ref, () => {
    setIsOpen(false)
  })

  return (
    <LanguageSwitcherWrapper ref={ref}>
      <Button
        aria-label='Přepínač jazyků'
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
      >
        {formatLocale(locale)} <ChevronIcon />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <List
            initial={{
              opacity: 0,
              rotateX: 30,
            }}
            animate={{
              opacity: 1,
              rotateX: 0,
              transition: {
                duration: 0.45,
              },
            }}
            exit={{
              opacity: 0,
              rotateX: -30,
              transition: {
                duration: 0.45,
                delay: 0.1,
              },
            }}
          >
            {locales?.map((locale, i) => {
              return (
                <ListItem
                  key={i}
                  onClick={() => {
                    router.push({ pathname, query }, asPath, { locale })
                    setIsOpen(false)
                  }}
                >
                  {formatLocale(locale)}
                </ListItem>
              )
            })}
          </List>
        )}
      </AnimatePresence>
    </LanguageSwitcherWrapper>
  )
}
export default LanguageSwitcher
