// Next.js
import Link from '../../Link'
import Image from "next/legacy/image"

// React components (svg)
import RegionMap from '../../svgs/RegionMap'
import RegionMapSk from '../../svgs/RegionMapSk'

// Styled Components
import {
  SubmenuWrapper,
  ContentWrapper,
  Content,
  Block,
  TitleItem,
  TitleLink,
  BlockItem,
  BlockLink,
  ImageWrapper,
  ExternalLink,
} from './Submenu.styles'

// Types
import { SubmenuProps } from './Submenu.types'

// Store
import useStore from '../../../utils/store'
import { REGIONS, REGIONS_TRANSLATION_MAP } from '../../../utils/constants'
import { compose, flatten, map } from 'ramda'
import { Api } from '../../../types/api'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'

// Constants
import { TABS_ABOUT } from '../../../utils/constants'

const Submenu = ({ serviceList }: SubmenuProps) => {
  const submenu = useStore(state => state.submenu)
  const setSubmenu = useStore(state => state.setSubmenu)
  const individualLocale = useDictionaryLine(
    dictionary.submenu.customers.individual,
  )
  const businessLocale = useDictionaryLine(
    dictionary.submenu.customers.business,
  )
  const communityLocale = useDictionaryLine(
    dictionary.submenu.customers.community,
  )

  const ids = [1, 5, 2, 6, 3]

  const sortedServiceCategories =
    serviceList?.serviceCategories.data.sort((a, b) => {
      const indexA = ids.indexOf(Number(a.id))
      const indexB = ids.indexOf(Number(b.id))
      if (indexA === -1 && indexB === -1) return 0
      if (indexA === -1) return 1
      if (indexB === -1) return -1
      return indexA - indexB
    }) || []

  const getContent = () => {
    switch (submenu) {
      case 'about':
        return (
          <ContentWrapper
            onMouseEnter={() => {
              setSubmenu('about')
            }}
          >
            <Content locations={true}>
              <ImageWrapper>
                <Image
                  src='/images/about.webp'
                  layout='fill'
                  alt='O společnosti'
                  objectFit='cover'
                  priority={true}
                />
              </ImageWrapper>
              <Block
                locations={true}
                onClick={() => {
                  setSubmenu('')
                }}
              >
                {TABS_ABOUT.map(item => {
                  return (
                    <BlockItem
                      key={item.id}
                      onClick={() => {
                        setSubmenu('')
                      }}
                    >
                      <Link
                        href={`/o-spolecnosti${item.href}`}
                        passHref
                        legacyBehavior
                      >
                        <BlockLink>{item.title}</BlockLink>
                      </Link>
                    </BlockItem>
                  )
                })}
              </Block>
            </Content>
          </ContentWrapper>
        )
      case 'locations':
        return (
          <ContentWrapper
            onMouseEnter={() => {
              setSubmenu('locations')
            }}
          >
            <Content locations={true}>
              {process.env.NEXT_PUBLIC_DEFAULT_LOCALE === 'cs' ? (
                <RegionMap />
              ) : (
                <RegionMapSk />
              )}
              <Block
                locations={true}
                onClick={() => {
                  setSubmenu('')
                }}
              >
                {REGIONS.map(region => {
                  return (
                    <BlockItem
                      key={region}
                      onClick={() => {
                        setSubmenu('')
                      }}
                    >
                      <Link
                        href={`/kontakt/${REGIONS_TRANSLATION_MAP[region]}#provozovny-a-sberne-dvory`}
                        passHref
                        legacyBehavior
                      >
                        <BlockLink>{region}</BlockLink>
                      </Link>
                    </BlockItem>
                  )
                })}
              </Block>
            </Content>
          </ContentWrapper>
        )
      case 'services':
        return (
          (<ContentWrapper
              onMouseEnter={() => {
                setSubmenu('services')
              }}
            >
            <Content>
              {sortedServiceCategories
                .filter(serviceCategory => {
                  return serviceCategory.attributes.services.data.length > 0
                })
                .map((category, i) => {
                  if (i === 4) {
                    return (<>
                      <Block
                        onClick={() => {
                          setSubmenu('')
                        }}
                      >
                        {sortedServiceCategories
                          .filter(category => {
                            return (
                              category.attributes.services.data.length === 0
                            )
                          })
                          .map(category => {
                            if (
                              category.attributes.name ===
                                'Udržitelné služby' ||
                              category.attributes.slug === 'service-category'
                            ) {
                              return (
                                <TitleItem key={category.id}>
                                  <ExternalLink
                                    href='https://www.esgavensis.cz'
                                    rel='noopener noreferrer'
                                    title='ESG Avensis'
                                    target='_blank'
                                  >
                                    <TitleLink>
                                      {category.attributes.name}
                                    </TitleLink>
                                  </ExternalLink>
                                </TitleItem>
                              )
                            }
                            return (
                              <TitleItem key={category.id}>
                                <Link
                                  href={`/sluzby/${category.attributes.slug}`}
                                  passHref
                                  legacyBehavior
                                >
                                  <TitleLink>
                                    {category.attributes.name}
                                  </TitleLink>
                                </Link>
                              </TitleItem>
                            )
                          })}
                      </Block>
                      <Block
                        key={category.id}
                        onClick={() => {
                          setSubmenu('')
                        }}
                      >
                        <TitleItem>
                          <Link
                            href={`/sluzby/${category.attributes.slug}`}
                            passHref
                            legacyBehavior
                          >
                            <TitleLink>{category.attributes.name}</TitleLink>
                          </Link>
                        </TitleItem>

                        {category.attributes.services.data.map(service => {
                          return (
                            (<BlockItem
                                key={service.id}
                                onClick={() => {
                                  setSubmenu('')
                                }}
                              >
                              <Link
                                href={`/sluzby/${
                                  category.attributes.slug
                                }#${service.attributes.name
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .split(' ')
                                  .join('-')
                                  .toLowerCase()}`}
                                passHref
                                legacyBehavior
                              >
                                <BlockLink>
                                  {service.attributes.name}
                                </BlockLink>
                              </Link>
                            </BlockItem>)
                          );
                        })}
                      </Block>
                    </>);
                  }
                  return (
                    (<Block
                        key={category.id}
                        onClick={() => {
                          setSubmenu('')
                        }}
                      >
                      <TitleItem>
                        <Link
                          href={`/sluzby/${category.attributes.slug}`}
                          passHref
                          legacyBehavior
                        >
                          <TitleLink>{category.attributes.name}</TitleLink>
                        </Link>
                      </TitleItem>
                      {category.attributes.services.data.map(service => {
                        return (
                          (<BlockItem
                              key={service.id}
                              onClick={() => {
                                setSubmenu('')
                              }}
                            >
                            <Link
                              href={`/sluzby/${
                                category.attributes.slug
                              }#${service.attributes.name
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .split(' ')
                                .join('-')
                                .toLowerCase()}`}
                              passHref
                              legacyBehavior
                            >
                              <BlockLink>{service.attributes.name}</BlockLink>
                            </Link>
                          </BlockItem>)
                        );
                      })}
                    </Block>)
                  );
                })}
            </Content>
          </ContentWrapper>)
        );
      case 'customers':
        const services = compose(
          flatten,
          map(
            (category: Api.ServiceCategory) =>
              category.attributes.services.data,
          ),
        )(serviceList?.serviceCategories.data || [])

        return (
          (<ContentWrapper
              onMouseEnter={() => {
                setSubmenu('customers')
              }}
            >
            <Content>
              {[
                [
                  (service: Api.Service) => service.attributes.forIndividual,
                  individualLocale,
                ],
                [
                  (service: Api.Service) => service.attributes.forCommunity,
                  communityLocale,
                ],
                [
                  (service: Api.Service) => service.attributes.forEntrepreneur,
                  businessLocale,
                ],
              ].map((block: any) => (
                <Block
                  key={block[1]}
                  onClick={() => {
                    setSubmenu('')
                  }}
                >
                  <TitleItem>
                    <TitleLink as='div'>{block[1]}</TitleLink>
                  </TitleItem>
                  {services.filter(block[0]).map(service => {
                    return (
                      (<BlockItem
                          key={service.id}
                          onClick={() => {
                            setSubmenu('')
                          }}
                        >
                        <Link
                          href={`/sluzby/${
                            service.attributes.category.data.attributes.slug
                          }#${service.attributes.name
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .split(' ')
                            .join('-')
                            .toLowerCase()}`}
                          passHref
                          legacyBehavior
                        >
                          <BlockLink>{service.attributes.name}</BlockLink>
                        </Link>
                      </BlockItem>)
                    );
                  })}
                </Block>
              ))}
            </Content>
          </ContentWrapper>)
        );
      default:
        return <></>
    }
  }
  return (
    <SubmenuWrapper
      onMouseLeave={() => {
        setSubmenu('')
      }}
    >
      {getContent()}
    </SubmenuWrapper>
  )
}
export default Submenu
