// Styled Components
import { ChevronIconWrapper } from './ChevronIcon.styles'

const ChevronIcon = () => {
  return (
    <ChevronIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 11.061 11.061'
    >
      <g data-name='Group 285' transform='translate(-1160.47 -1410.808)'>
        <path
          fill='none'
          stroke='#575757'
          strokeWidth='1.5'
          d='M1161 1415.764l5.044 5.044 4.956-4.955'
          data-name='Path 8'
        ></path>
      </g>
    </ChevronIconWrapper>
  )
}
export default ChevronIcon
