import styled from 'styled-components'

// Types
import { BurgerIconWrapperProps } from './BurgerIcon.types'

export const BurgerIconWrapper = styled.svg<BurgerIconWrapperProps>`
  & > g {
    stroke: ${({ color, theme }) => color && theme.colors.burgerIcon[color]};
  }
`
