import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  position: relative;
  z-index: 1;
  font-size: ${({ theme }) => 12 / theme.fontSizes.root + 'rem'};
  padding: 0 36px 24px;
  max-width: 1676px;
  width: 100%;
  margin: auto auto 0;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    font-size: ${({ theme }) => 15 / theme.fontSizes.root + 'rem'};
    padding: 0 36px 48px;
  }
`

export const MainContainer = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.colors.footer.border};

  @media (${({ theme }) => theme.mediaQueries.md}) {
    padding-top: 6px;
    font-size: ${({ theme }) => 15 / theme.fontSizes.root + 'rem'};
  }
`

export const LogoWrapper = styled.div`
  svg {
    height: 59px;
  }

  @media (${({ theme }) => theme.mediaQueries.md}) {
    svg {
      height: 98px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
`

export const HelplineText = styled.p`
  color: ${({ theme }) => theme.colors.footer.textHelpline};
  margin-top: 30px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (${({ theme }) => theme.mediaQueries.md}) {
    margin-top: 0;
  }
`

export const FooterLink = styled.a`
  cursor: pointer;
  display: block;
  color: ${({ theme }) => theme.colors.footer.textLink};
  text-decoration: none;
  margin-top: 6px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    margin-top: 24px;
  }
`

export const CopyText = styled.p`
  color: ${({ theme }) => theme.colors.footer.textCopyMobile};
  margin-top: 30px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    color: ${({ theme }) => theme.colors.footer.textCopyDesktop};
  }
`

export const DesktopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 240px;
`

export const SocialIconsBarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
`

export const InfoContainer = styled.div``
