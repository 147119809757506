// Types
import { BurgerIconProps } from './BurgerIcon.types'

// Styled Components
import { BurgerIconWrapper } from './BurgerIcon.styles'

const BurgerIcon = ({ color }: BurgerIconProps) => {
  return (
    <BurgerIconWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='37'
      height='15.5'
      viewBox='0 0 37 15.5'
      color={color}
    >
      <g
        fill='none'
        stroke='#fff'
        strokeWidth='1.5'
        data-name='Group 415'
        transform='translate(-299.5 -39.75)'
      >
        <path
          d='M37 0L0 0'
          data-name='Line 211'
          transform='translate(299.5 40.5)'
        ></path>
        <path
          d='M28 0L0 0'
          data-name='Line 212'
          transform='translate(308.5 47.5)'
        ></path>
        <path
          d='M18 0L0 0'
          data-name='Line 213'
          transform='translate(318.5 54.5)'
        ></path>
      </g>
    </BurgerIconWrapper>
  )
}
export default BurgerIcon
