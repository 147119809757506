// Next.js
import { useRouter } from '../../../hooks/router'

// React components
import ChevronIcon from '../../svgs/icons/ChevronIcon'

// Styled Components
import { NavListWrapper, NavItem } from './NavList.styles'
import { BoldText } from '../../../styled-components/shared/common'

// Store
import useStore from '../../../utils/store'
import { Store } from '../../../types/store'

// Types
import { NavListProps } from './NavList.types'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'

const NavList = ({ darkBackground, career }: NavListProps) => {
  const router = useRouter()
  const submenu = useStore(state => state.submenu)
  const setSubmenu = useStore(state => state.setSubmenu)

  const servicesLocale = useDictionaryLine(dictionary.mainNavigation.services)
  const customersLocale = useDictionaryLine(dictionary.mainNavigation.customers)
  const locationsLocale = useDictionaryLine(dictionary.mainNavigation.locations)
  const aboutLocale = useDictionaryLine(dictionary.mainNavigation.about)
  const whyAVE = useDictionaryLine(dictionary.career.whyLabel)
  const lookingForTitle = useDictionaryLine(dictionary.career.lookingFor.title)
  const storiesLocale = useDictionaryLine(dictionary.career.stories.title)
  const workLocale = useDictionaryLine(dictionary.career.header.work)

  const onMouseLeave = () => setSubmenu('')
  const onMouseEnter = (submenu: Store.SubmenuViewType) => () =>
    setSubmenu(submenu)

  return (
    <NavListWrapper>
      {career ? (
        <>
          <NavItem
            darkBackground={darkBackground}
            onMouseLeave={onMouseLeave}
            onClick={() => {
              router.push('/o-ave')
            }}
          >
            {whyAVE.replace('?', '')}
          </NavItem>
          <NavItem
            darkBackground={darkBackground}
            onMouseLeave={onMouseLeave}
            onClick={() => {
              router.push('/prace-v-ave')
            }}
          >
            {workLocale}
          </NavItem>
          <NavItem
            darkBackground={darkBackground}
            onMouseLeave={onMouseLeave}
            onClick={() => {
              router.push('/hledame')
            }}
          >
            <BoldText>{lookingForTitle}</BoldText>
          </NavItem>
          <NavItem
            darkBackground={darkBackground}
            onMouseLeave={onMouseLeave}
            onClick={() => {
              router.push('/pribehy-kolegu')
            }}
          >
            {storiesLocale}
          </NavItem>
        </>
      ) : (
        <>
          <NavItem
            darkBackground={darkBackground}
            active={submenu === 'services'}
            onMouseEnter={onMouseEnter('services')}
            onMouseLeave={onMouseLeave}
          >
            {servicesLocale} <ChevronIcon />
          </NavItem>
          <NavItem
            darkBackground={darkBackground}
            active={submenu === 'customers'}
            onMouseEnter={onMouseEnter('customers')}
            onMouseLeave={onMouseLeave}
          >
            {customersLocale} <ChevronIcon />
          </NavItem>
          {router.locale !== 'en' && (
            <NavItem
              darkBackground={darkBackground}
              active={submenu === 'locations'}
              onMouseLeave={onMouseLeave}
              onMouseEnter={onMouseEnter('locations')}
            >
              {locationsLocale} <ChevronIcon />
            </NavItem>
          )}

          <NavItem
            darkBackground={darkBackground}
            active={submenu === 'about'}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter('about')}
            onClick={() => {
              router.push('/o-spolecnosti')
            }}
          >
            {aboutLocale}
            <ChevronIcon />
          </NavItem>
        </>
      )}
    </NavListWrapper>
  )
}
export default NavList
