const translateMap = {
  '/o-spolecnosti': '/about-us',
  '/ochrana-udaju': '/data-protection',
  '/novinky': '/news',
  '/novinky/:slug': '/news/:slug',
  '/zadost': '/request',
  '/vyhledavani': '/search',
  '/tiskove-zpravy': '/press',
  '/tiskove-zpravy/:slug': '/press/:slug',
  '/sluzby': '/services',
  '/sluzby/:slug': '/services/:slug',
  '/kontakt': '/contact',
  '/kontakt/:region': '/contact/:region',
  '/kontakt/:region/:slug': '/contact/:region/:slug',
  '/o-ave': '/about-ave',
  '/prace-v-ave': '/work-in-ave',
  '/pribehy-kolegu': '/our-stories',
  '/hledame': '/searching',
  '/hledame/:slug': '/searching/:slug',
  '/pribehy': '/stories',
  '/pribehy/:slug': '/stories/:slug',
  '/informace-o-cookies': '/cookies-information',
}

module.exports = translateMap
